.intest {
  opacity: 0;
}

.notintest {
  opacity: 1;
}

.app-sidebar:hover {
  opacity: 1;
}

/* Custom Tooltip Styles */
.myTooltipClass {
  background: linear-gradient(to bottom, #3498db, #2980b9);
  color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  animation: bounceIn 1s ease;
  -webkit-animation: bounceIn 1s ease;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
}

.myTooltipClass.introjs-tooltip-show {
  opacity: 1;
}

@keyframes bounceIn {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-15px);
  }

  60% {
    transform: translateY(-10px);
  }
}



/* Custom Highlight Styles */
.myHighlightClass {
  border: 2px solid #e74c3c;
  box-shadow: 0 0 10px rgba(231, 76, 60, 0.5);

}

.introjs-tooltiptext {
  color: white !important;
  font-size: 14px;
}