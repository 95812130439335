.login-btn {
  align-items: center;
  background: linear-gradient(100deg,#0306ae, #FFFFFF) !important;
  border: 1px solid #111 !important;
  border-radius: 8px  !important;
  box-sizing: border-box;
  color: #ffff !important;
  font-weight: bolder !important;
  cursor: pointer;
  display: flex !important;
  font-size: 18px !important;
  height: 48px !important;
  justify-content: center !important;
  line-height: 24px !important;
  max-width: 100% !important;
  padding: 0 25px !important;
  position: relative !important;
  text-align: center !important;
  text-decoration: none !important;
  user-select: none !important;
  -webkit-user-select: none !important;
  touch-action: manipulation !important;
  text-transform: initial !important;   
  margin-top: 20px !important;
  font-family: "Public Sans", sans-serif !important;
  letter-spacing: 2px !important;
}

.login-btn:after {
  background-color: #111 !important;
  border-radius: 8px !important;
  content: "" !important;
  display: block !important;
  height: 48px !important;
  left: 0 !important;
  width: 100% !important;
  position: absolute !important;
  top: -1px !important;
  transform: translate(8px, 8px) !important;
  transition: transform .2s ease-out !important;
  z-index: -1 !important;
}

.login-btn:hover:after {
  transform: translate(0, 0) !important;
}

.login-btn:active {
  background-color: #131587 !important;
  outline: 0 !important;
}

.login-btn:hover {
  outline: 0 !important;
  background: linear-gradient(150deg, #FFFFFF, #131587) !important;
}

@media (min-width: 768px) {
  .login-btn {
    padding: 0 40px !important;
  }
}

@media screen and (max-width: 500px) {
  .MuiTextField-root {
    display: flex !important;
    width: 86vw !important;
  }
}

#otpdiv{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
}

#otpdiv input {
  justify-content: center;
  width: 4em !important;
  height: 3em !important;
  border: 2px solid #03045e !important;
  background-color: transparent !important;
  border-radius: 10px !important;
  width: 50px !important;
  height: 50px !important;
  font-size: 20px !important;
  color: #1D1D1D !important;
  margin: 0 5px !important;
}

#otpdiv input:focus {
  outline: none !important;
  border: 2px solid #03045e !important;
}

@media screen and (max-width: 991px) and (min-width: 663px) {
  .sendotp {
    margin-top: 3px !important;
    margin-left: 8px !important;
  }
}

@media screen and (max-width: 530px) {
  #otpdiv input {
    justify-content: center;
    width: 1.6em !important;
    height: 2em !important;
    margin-left: 5px !important;
  }

  #otpdiv {
    width: 60vw !important;
  }

  #otpdiv2 {
    width: 58vw !important;
  }

  #logindiv {
    width: 60vw !important;
  }

  #paragraphotp {
    width: 49vw !important;
  }

  #otpdiv2 {
    margin-left: 0px !important;
  }
}

input::file-selector-button {
  border: 1;
  cursor: pointer;
  border-radius: 8px;
  padding: 1rem 1.25rem;
  text-shadow: 0 1px 1px #333;
  background: white;
  color: rgba(0, 158, 247, 1);
}

#preferswitch .MuiFormControlLabel-label {
  color: black !important;
}

#otpdiv2 {
  margin-left: 60px;
}

.selectlogin {
  width: 156px;
}

#Loginbutton {
  width: 100% !important;
}

#Loginbutton button {
  width: 100% !important;
  display: flex;
  justify-content: center;
}

.gradient-background {
  background: linear-gradient(45deg, #f5f5f5, #03045e);
}