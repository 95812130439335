@media screen and (max-width: 990px) {
  #kt_app_header_wrapper {
    display: flex !important;
    justify-content: flex-end !important;
    width: 80vw;
  }
}

@media screen and (max-width: 450px) {
  #kt_app_header_wrapper {
    display: flex !important;
    justify-content: flex-end !important;
    width: 70vw;
  }
}

#kt_app_header_wrapper {
  display: flex !important;
  justify-content: flex-end !important;
  padding-right: 20px;
}

#google_translate_element {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 500px) {
  .goog-te-gadget {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
