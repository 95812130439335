@media screen and (max-width: 991px) {
  .heightofterms {
    margin-top: 18vh;
  }
}

@media screen and (max-width: 530px) {
  .heightofterms {
    margin-top: 18vh;
  }

  .maincard {
    padding: 1rem !important;
    width: auto !important;
  }
}

.maincard {
  width: 475px;
  padding: 1.5rem !important;
}
