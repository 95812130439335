.recording-icon {
  display: flex;
  align-items: center;
  background-color: #ffeaea;
  padding: 10px 20px;
  border-radius: 10px;
  user-select: none;
  cursor: initial;
}

.recording-dot {
  width: 12px;
  height: 12px;
  background-color: #ff4444;
  border-radius: 50%;
  margin-right: 10px;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.recording-text {
  font-size: 12px;
  font-weight: bold;
  color: #000;
}
