.colordiv {
    padding: 10px;
    width: 10px;
    height: 6px;
    display: inline-block;
    margin-right: 6px;
}

.grey {
    background: grey;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.green {
    background: green;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.para-color {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    margin-right: 10px;
    margin-bottom: 0px;
}

#summaryparentdiv .MuiCardContent-root {
    overflow: auto;
    max-height: 40vh !important;
}

.parentdivcolorlabel {
    margin-bottom: 4px;
    width: -webkit-fill-available;
    justify-content: center;
    align-items: center;
    /* margin-top: 10px; */
}