/* @media screen and (max-width: 731px) {
    .result-span {
        position: absolute;
        bottom: 23px;
        left: 18px;
    }
} */

@media screen and (max-width: 500px) {
  .attemptedquestion {
    position: absolute;
    top: 58px;
  }

  .attemptedseriesmaindiv {
    width: 90vw;
  }
}

.download-button {
  margin-left: 0px;
  margin-bottom: 0px;
}

#retestid {
  color: white;
}

#retestid:hover {
  border-bottom: 1px solid rgb(255, 253, 253);
}

.MuiAccordionSummary-content {
  display: block !important;
}