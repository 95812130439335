.datentime {
  font-weight: 400;
  font-size: 14px;
}

.datenjoinee {
  font-size: 14px;
}

.duration {
  margin-bottom: -6px;
}

.wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.playbutton {
  width: 128px;
  height: 40px;
  /* font-family: 'Roboto', sans-serif; */
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  color: #fff;
  background-color: #009cf5;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  /* margin-left: 14px; */
  margin-top: 12px;
}

.playbutton:hover {
  background-color: #fff;
  box-shadow: 0px 2px 20px #fff;
  color: #009cf5;
  /* transform: translateY(-7px); */
}

.paragraphstart {
  top: 10px;
  position: relative;
  left: 17px;
}

.card-title {
  font-size: 23px !important;
  margin: 1px 0px !important;
}

.permissionsicons {
  margin-right: 12px;
}
    


.maincardforhover {
  background: rgb(92, 93, 219) ;
  background: linear-gradient(20deg, rgb(92, 93, 219) 0%, rgb(3, 4, 94) 100%);
  // background: white;   
  border-radius: 6px;
  max-height: 426px;
  overflow: hidden;
  position: relative;
  top: 0px;
  width: auto;  
  transition: all 500ms ease-in-out;  
  
  &:hover {
    box-shadow: 0 0 8px #d3d3d3;    
    cursor: pointer;
    max-height: 426px;
    position: relative;
    top: -6px;

 
  }


}

.assigned-test-card{
  background-color: var(--kt-app-sidebar-bg-color) !important;

  &-title {
    color: var(--kt-app-layout-text-color) !important;
  }
  &-visibility-icon{
    color: var(--kt-app-sidebar-title-color) !important;
  }
  &-icon{
    color: var(--kt-app-sidebar-title-color) !important;
  }
  &-duration{
    color: var(--kt-app-layout-grey-text-color) !important;
  }
}
