#kt_app_content_container2 {
  margin-left: -12vw;
}

.MuiPaper-outlined {
  height: fit-content;
  padding-bottom: 20px;
}

@media screen and (max-width: 990px) {
  #kt_app_content_container2 {
    margin-left: 0vw;
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.rerenderstate {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

* {
  scroll-behavior: smooth;
}

.MuiSnackbar-root {
  bottom: 24px !important;
  /* left: 60vw !important; */
}

.submitdiv {
  width: 86vw;
  display: flex;
  justify-content: flex-end;
}

.pre-requsite-webcam {
  border-radius: 8px;
  height: fit-content;
  width: 300px;
  overflow: hidden;
}

@media screen and (max-width: 800px) {
  .MuiSnackbar-root {
    bottom: 24px !important;
    /* left: 40vw !important; */
  }
}

@media screen and (max-width: 480px) {
  .MuiSnackbar-root {
    bottom: 24px !important;
    /* left: 16vw !important; */
  }
}

@media screen and (max-width: 990px) {
  .submitdiv {
    width: 97vw;
  }

  .refreshgif {
    width: 169px !important;
    top: 82vh !important;
  }
}

.refreshgif {
  height: 40px;
  width: 60px;
  left: 22px;
  top: 92vh;
  color: rgb(0, 0, 0);
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  box-shadow: 1px 1px 5px rgb(0 0 0 / 48%) !important;
  position: fixed !important;
  z-index: 1399 !important;
}

.time {
  background: lightgray;
  color: black;
  font-weight: 500;
  padding: 3px 3px;
}

.refreshgif2 {
  left: 2px;
  bottom: 20px;
  color: rgb(0, 0, 0);
  text-align: center;
  position: fixed !important;
  z-index: 1399 !important;

  /* resize: both; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: fixed !important;
  width: 260px;
  height: 260px;
}

.refreshgif2 video {
  object-fit: cover;
}

@media only screen and (min-width: 658px) {
  .leftcontainer {
    border-right: none !important;
    width: 32px;
  }

  .rightcontainer {
    margin-left: 67px;
  }
}

.anticheatheading {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.timer-badge {
  font-size: 1.5rem;
  padding: 6px;
}

.timer-part {
  margin: 0 5px;
}

.timer-msg {
  align-items: center;
  display: flex;
}

figure.image > img {
  max-width: 72vw;
}

figure.image {
  overflow: auto;
}


.prerequisite-buttons {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px !important;
  width: 250px;
  height: 50px;
  background-color: #1f219c;
  color: #ffffff;
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  align-items: center;
  border-radius: 0.5rem;
  user-select: none;
  gap: 0.75rem;
  box-shadow: 0 4px 6px -1px rgba(72, 83, 236, 0.755) 0 2px 4px -1px #488aec17;
  transition: all 0.6s ease;
}

.prerequisite-buttons:hover {
  box-shadow: 0 10px 15px -3px rgba(72, 83, 236, 0.755), 0 4px 6px -2px #488aec17;
}

.prerequisite-buttons:focus,
.prerequisite-buttons:active {
  opacity: 0.85;
  box-shadow: none;
}

@media screen and (min-width: 1368px) {
  .pre-requsite-webcam{
    width: 500px;
  }
}