* {
  font-family: "Mulish", sans-serif;
}

html {
  --scrollbarBG: black;
  --thumbBG: #0a0dd8;
  /* overflow-x: hidden; */
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  width: 6px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--thumbBG) !important;
  border-radius: 10px;
  width: 6px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--thumbBG);
}

/* .goog-te-banner-frame {
  visibility: hidden !important;
  display: none !important;
} */

h1,h2,h3,h4,h5,h6{
  font-family: "Noto Sans", sans-serif !important;
}

.gradient-text {
  background: #03045e 51%;
  background: linear-gradient(to bottom right, #03045e 51%, #5455a4 56%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.styled-btn {
  color: #03045e;
  text-transform: uppercase;
  text-decoration: none;
  border: 2px solid #03045e;
  padding: 5px 8px;
  font-size: 17px;
  font-weight: bold;
  background: transparent;
  position: relative;
  transition: all 1s;
  overflow: hidden;
  width: 200px;
}

.styled-btn.disabled {
  color: #000000f5;
  opacity: 0.5;
  cursor: not-allowed; /* Change cursor to indicate disabled state */
}

.styled-btn:hover {
  color: white;
}

.styled-btn::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 0%;
  top: 0;
  left: -40px;
  transform: skewX(45deg);
  background-color: #03045e;
  z-index: -1;
  transition: all 1s;
}

.styled-btn:hover::before {
  width: 180%;
}


@keyframes scaleUp {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}


.flip-clock {
  --fcc-flip-duration: 0.5s; /* transition duration when flip card */
  --fcc-spacing: 8px; /* space between unit times and separators */
  --fcc-digit-block-width: 30px; /* width of digit card */
  --fcc-digit-block-height: 40px; /* height of digit card, highly recommend in even number */
  --fcc-digit-block-radius: 5px; /* border radius of digit card */
  --fcc-digit-block-spacing: 5px; /* space between blocks in each unit of time */
  --fcc-digit-font-size: 20px; /* font size of digit */
  --fcc-digit-color: white; /* color of digit */
  --fcc-label-font-size: 10px; /* font size of label */
  --fcc-label-color: #ffffff; /* color of label */
  --fcc-background: #03045e; /* background of digit card */
  --fcc-divider-color: white; /* color of divider */
  --fcc-divider-height: 1px; /* height of divider */
  --fcc-separator-size: 6px; /* size of colon */
  --fcc-separator-color: #5455a4; /* color of colon */
}

.glass-button {
  background: rgba(3, 4, 94, 0.9) !important; /* Semi-transparent dark blue */
  border-radius: 5px !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(10px) !important; /* Blur effect */
  -webkit-backdrop-filter: blur(10px) !important; /* Blur effect for Safari */
  border: 1px solid rgba(255, 255, 255, 0.3) !important; /* Light border */
  color: white !important;
  padding: 5px !important; 
  text-transform: uppercase !important;
  font-weight: bold !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
}

.glass-button:hover {
  background: rgba(3, 4, 94, 0.6) !important; /* Slightly less transparent on hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15) !important;
}

.btn2 {
  border: none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 3px  !important;
  background-color: #1f219c !important;
  color: #ffffff !important;
  font-size: 0.7rem !important;
  line-height: 1rem !important;
  font-weight: 700 !important;
  cursor: pointer !important;
  text-align: center !important;
  text-transform: uppercase !important;;
  vertical-align: middle !important;;
  border-radius: 0.5rem !important;;
  user-select: none !important;
  gap: 0.8rem !important;
  box-shadow: 0 4px 6px -1px rgba(72, 83, 236, 0.755) 0 2px 4px -1px #488aec17 !important;;
  transition: all 0.6s ease !important;;
  width: 150px !important;
}

.btn2:hover {
  box-shadow: 0 10px 15px -3px rgba(72, 83, 236, 0.755), 0 4px 6px -2px #488aec17 !important;
}

.btn2:focus,
.btn2:active {
  opacity: 0.85 !important;
  box-shadow: none !important;
}




img {
  pointer-events: none;
  user-select: none;
}

.introjs-tooltiptext {
  color: black;
}

pre code {
  font-size: 0.875em !important;
  color: var(--bs-code-color) !important;
  word-wrap: break-word !important;
}

.MuiTooltip-tooltip {
  font-size: 0.875em !important;
  word-wrap: break-word !important;
}

.secondmain .card-header {
  display: flex;
  justify-content: center;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.blinking-text {
  animation: blink 1s linear infinite;
}


@media screen and (max-width: 768px) {
  .styled-btn {
    padding: 1px;
    width: 150px;
    height : 40px;
    font-size: 12px;
    margin-top: 20px;
  }
}


/*Questions summary scroll bar*/
/* #summaryparentdiv::-webkit-scrollbar {
  width: 8px !important;
}

#summaryparentdiv::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

#summaryparentdiv::-webkit-scrollbar-thumb {
  background: #5714b4 !important;
  border-radius: 4px;
}

#summaryparentdiv::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}  */