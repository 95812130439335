.bottomfootertext {
  font-size: 14px;
  margin-left: 4vw;
  margin-right: -15px;
}

@media screen and (max-width: 1286px) {
  .bottomfootertext {
    margin-right: -15px;
  }
}

@media screen and (max-width: 940px) {
  .modalfooterparent {
    display: flex;
    width: 94%;
    flex-direction: column;
  }
}

@media screen and (max-width: 687px) {
  .parentdivheader {
    display: flex;
    flex-direction: column;
  }

  .ptag {
    margin-bottom: -4px;
  }
}

.parentdivheader {
  display: flex;
}
