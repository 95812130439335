.optionparentdiv {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 !important;
}

.optionparentdiv > p {
  margin: 0 !important;
  height: 19px !important;
}

.ck-content h2 h1 h3 h4 h5 p {
  color: black !important;
}

.question-card-title>h1,
.question-card-title>h2,
.question-card-title>h3,
.question-card-title>h4,
.question-card-title>h5,
.question-card-title>h6 {
  color: #03045e !important;
}

.question-input-fillups{
  border: 0;
    border-bottom: 1px solid black;
    outline: 0;
    width: 162px;
}

.fill-in-the-blank-question p > u {
  text-decoration: dashed underline !important;
  text-underline-offset: 5px;
}

.fill-in-the-blank-question-correct-answer > p {
  margin: 0 !important;
}