.seriesContainer {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  margin-left: 8px;
  margin-bottom: 10px;
}

@media screen and (max-width: 1167px) {
  .seriesContainer {
    grid-template-columns: 50% 50%;
  }
}

@media screen and (max-width: 500px) {
  .seriesContainer {
    grid-template-columns: 94%;
  }
}

@media screen and (max-width: 990px) {
  .filterbuttonparentdiv {
    width: 95vw !important;
    margin-bottom: 6px !important;
    position: relative;
    right: 24px;
  }
}

@media screen and (min-width: 1300px) {
  .filterbuttonparentdiv {
    width: 100% !important;
    margin-bottom: -4px !important;
  }
}

@media screen and (max-width: 1142) {
  .filterbuttonparentdiv {
    width: 100% !important;
    margin-bottom: -4px !important;
  }
}

.filterbuttonparentdiv {
  width: 71vw;
  display: flex;
  justify-content: flex-end;
  margin-top: 17px;
  margin-bottom: -4px !important;
  position: relative;
  right: 14px;
}