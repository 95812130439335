//
// Aside
//

// General mode
.app-aside {
  transition: $app-aside-base-transition;
}

// Utilities
.app-aside-minimize-d-flex,
.app-aside-sticky-d-flex,
.app-aside-collapse-d-flex,
.app-aside-minimize-mobile-d-flex,
.app-aside-collapse-mobile-d-flex {
  display: none;
}

// Desktop mode
@include media-breakpoint-up(lg) {
  // Base
  .app-aside {
    display: flex;
    flex-shrink: 0;
    width: var(--kt-app-aside-width);
    background-color: var(--kt-app-aside-base-bg-color);
    box-shadow: var(--kt-app-aside-base-box-shadow);
    border-left: var(--kt-app-aside-base-border-left);
    border-right: var(--kt-app-aside-base-border-right);

    @include property(margin-left, $app-aside-base-gap-start);
    @include property(margin-right, $app-aside-base-gap-end);
    @include property(margin-top, $app-aside-base-gap-top);
    @include property(margin-bottom, $app-aside-base-gap-bottom);
    @include property(z-index, $app-aside-base-z-index);
  }

  // Vars
  :root {
    --kt-app-aside-width: #{$app-aside-base-width};
    --kt-app-aside-width-actual: #{$app-aside-base-width};

    --kt-app-aside-gap-start: #{$app-aside-base-gap-start};
    --kt-app-aside-gap-end: #{$app-aside-base-gap-end};
    --kt-app-aside-gap-top: #{$app-aside-base-gap-top};
    --kt-app-aside-gap-bottom: #{$app-aside-base-gap-bottom};
  }

  [data-kt-app-aside-stacked="true"] {
    --kt-app-aside-width: calc(
      var(--kt-app-aside-primary-width) + var(--kt-app-aside-secondary-width)
    );
  }

  [data-kt-app-aside-minimize="on"] {
    --kt-app-aside-width: #{$app-aside-minimize-width};

    --kt-app-aside-gap-start: #{$app-aside-minimize-gap-start};
    --kt-app-aside-gap-end: #{$app-aside-minimize-gap-end};
    --kt-app-aside-gap-top: #{$app-aside-minimize-gap-top};
    --kt-app-aside-gap-bottom: #{$app-aside-minimize-gap-bottom};
  }

  [data-kt-app-aside-sticky="on"] {
    --kt-app-aside-width: #{$app-aside-sticky-width};

    --kt-app-aside-gap-start: #{$app-aside-sticky-gap-start};
    --kt-app-aside-gap-end: #{$app-aside-sticky-gap-end};
    --kt-app-aside-gap-top: #{$app-aside-sticky-gap-top};
    --kt-app-aside-gap-bottom: #{$app-aside-sticky-gap-bottom};
  }

  [data-kt-app-aside-collapse="on"] {
    --kt-app-aside-width: 0px;
  }

  // States
  .app-aside {
    [data-kt-app-aside-static="true"] & {
      position: relative;
    }

    [data-kt-app-aside-offcanvas="true"] & {
      display: none;
    }

    [data-kt-app-aside-fixed="true"] & {
      position: fixed;
      @include property(z-index, $app-aside-fixed-z-index);
      @include property(top, $app-aside-fixed-top);
      @include property(bottom, $app-aside-fixed-bottom);
      @include property(right, $app-aside-fixed-right);
    }

    [data-kt-app-aside-stacked="true"] & {
      align-items: stretch;
    }

    [data-kt-app-aside-sticky="on"] & {
      position: fixed;
      transition: $app-aside-base-transition;

      box-shadow: var(--kt-app-aside-sticky-box-shadow);
      border-left: var(--kt-aside-sticky-border-start);
      border-right: var(--kt-app-aside-sticky-border-end);

      @include property(top, $app-aside-sticky-top);
      @include property(bottom, $app-aside-sticky-bottom);
      @include property(left, $app-aside-sticky-left);
      @include property(z-index, $app-aside-sticky-z-index);
      @include property(margin-left, $app-aside-sticky-gap-start);
      @include property(margin-right, $app-aside-sticky-gap-end);
      @include property(margin-top, $app-aside-sticky-gap-top);
      @include property(margin-bottom, $app-aside-sticky-gap-bottom);
    }

    [data-kt-app-aside-minimize="on"] & {
      transition: $app-aside-base-transition;

      background-color: var(--kt-app-aside-minimize-bg-color);
      box-shadow: var(--kt-app-aside-minimize-box-shadow);
      border-start: var(--kt-app-aside-minimize-border-start);
      border-end: var(--kt-app-aside-minimize-border-end);

      @include property(margin-left, $app-aside-minimize-gap-start);
      @include property(margin-right, $app-aside-minimize-gap-end);
      @include property(margin-top, $app-aside-minimize-gap-top);
      @include property(margin-bottom, $app-aside-minimize-gap-bottom);
    }

    [data-kt-app-aside-hoverable="true"] & {
      .app-aside-wrapper {
        width: var(--kt-app-aside-width-actual);
      }
    }

    [data-kt-app-aside-hoverable="true"][data-kt-app-aside-minimize="on"]
      &:hover:not(.animating) {
      transition: $app-aside-base-transition;
      width: var(--kt-app-aside-width-actual);
      box-shadow: var(--kt-app-aside-minimize-hover-box-shadow);
    }

    [data-kt-app-aside-collapse="on"] & {
      transition: $app-aside-base-transition;
      width: var(--kt-app-aside-width-actual);
      margin-right: calc(-1 * var(--kt-app-aside-width-actual));
    }
  }

  // Utilities
  [data-kt-app-aside-minimize="on"] {
    .app-aside-minimize-d-none {
      display: none !important;
    }

    .app-aside-minimize-d-flex {
      display: flex !important;
    }
  }

  [data-kt-app-aside-sticky="on"] {
    .app-aside-sticky-d-none {
      display: none !important;
    }

    .app-aside-sticky-d-flex {
      display: flex !important;
    }
  }

  [data-kt-app-aside-collapse="on"] {
    .app-aside-collapse-d-none {
      display: none !important;
    }

    .app-aside-collapse-d-flex {
      display: flex !important;
    }
  }

  // Integration
  .app-aside {
    // Header
    [data-kt-app-aside-fixed="true"][data-kt-app-header-fixed="true"]:not([data-kt-app-aside-push-header="true"])
      & {
      top: var(--kt-app-header-height);
    }

    // Toolbar
    [data-kt-app-aside-fixed="true"][data-kt-app-header-fixed="true"][data-kt-app-toolbar-fixed="true"]:not([data-kt-app-aside-push-toolbar="true"])
      & {
      top: calc(
        var(--kt-app-header-height) + var(--kt-app-toolbar-height, 0px)
      );
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  // Base
  .app-aside {
    display: none;
    width: var(--kt-app-aside-width);
    @include property(z-index, $app-aside-base-z-index-mobile);

    background-color: var(--kt-app-aside-base-bg-color-mobile);
    box-shadow: var(--kt-app-aside-base-box-shadow-mobile);
    border-left: var(--kt-app-aside-base-border-start-mobile);
    order-right: var(--kt-app-aside-base-border-end-mobile);

    @include property(margin-left, $app-aside-base-gap-start-mobile);
    @include property(margin-right, $app-aside-base-gap-end-mobile);
    @include property(margin-top, $app-aside-base-gap-top-mobile);
    @include property(margin-bottom, $app-aside-base-gap-bottom-mobile);
  }

  // Vars
  :root {
    --kt-app-aside-width: #{$app-aside-base-width-mobile};
    --kt-app-aside-width-actual: #{$app-aside-base-width-mobile};

    --kt-app-aside-gap-start: #{$app-aside-base-gap-start-mobile};
    --kt-app-aside-gap-end: #{$app-aside-base-gap-end-mobile};
    --kt-app-aside-gap-top: #{$app-aside-base-gap-top-mobile};
    --kt-app-aside-gap-bottom: #{$app-aside-base-gap-bottom-mobile};
  }

  [data-kt-app-aside-minimize-mobile="on"] {
    --kt-app-aside-width: #{$app-aside-minimize-width-mobile};

    --kt-app-aside-gap-start: #{$app-aside-minimize-gap-start-mobile};
    --kt-app-aside-gap-end: #{$app-aside-minimize-gap-end-mobile};
    --kt-app-aside-gap-top: #{$app-aside-minimize-gap-top-mobile};
    --kt-app-aside-gap-bottom: #{$app-aside-minimize-gap-bottom-mobile};
  }

  [data-kt-app-aside-collapse-mobile="on"] {
    --kt-app-aside-width: 0px;
  }

  // States
  .app-aside {
    [data-kt-app-aside-stacked="true"] & {
      align-items: stretch;
    }

    [data-kt-app-aside-minimize-mobile="on"] & {
      transition: $app-aside-base-transition;
      background-color: var(--kt-app-aside-minimize-bg-color-mobile);
      box-shadow: var(--kt-app-aside-minimize-box-shadow-mobile);
      border-left: var(--kt-app-aside-minimize-border-start-mobile);
      border-right: var(--kt-app-aside-minimize-border-end-mobile);

      @include property(margin-left, $app-aside-minimize-gap-start-mobile);
      @include property(margin-right, $app-aside-minimize-gap-end-mobile);
      @include property(margin-top, $app-aside-minimize-gap-top-mobile);
      @include property(margin-bottom, $app-aside-minimize-gap-bottom-mobile);
    }

    [data-kt-app-aside-hoverable-mobile="true"] & {
      .app-aside-wrapper {
        width: var(--kt-app-aside-width-actual);
      }
    }

    [data-kt-app-aside-hoverable-mobile="true"][data-kt-app-aside-minimize-mobile="on"]
      &:hover:not(.animating) {
      transition: $app-aside-base-transition;
      width: var(--kt-app-aside-width-actual);
      box-shadow: var(--kt-app-aside-minimize-hover-box-shadow-mobile);
    }

    [data-kt-app-aside-collapse-mobile="on"] & {
      transition: $app-aside-base-transition;
      width: var(--kt-app-aside-width-actual);
      margin-right: calc(-1 * var(--kt-app-aside-width-actual));
    }
  }

  // Utilities
  [data-kt-app-aside-minimize-mobile="on"] {
    .app-aside-minimize-mobile-d-none {
      display: none !important;
    }

    .app-aside-minimize-mobile-d-flex {
      display: flex !important;
    }
  }

  [data-kt-app-aside-collapse-mobile="on"] {
    .app-aside-collapse-mobile-d-none {
      display: none !important;
    }

    .app-aside-collapse-mobile-d-flex {
      display: flex !important;
    }
  }
}
