.maincontainer {
    margin-top: -14px !important;
}

.secondmain {
    display: flex;
    justify-content: center;

}



.cardmain {

    margin: 0px 20px;
    margin-bottom: 20px;
    height: 180px;
    width: 260px;
    background: linear-gradient(20deg, #5c5ddb, #03045e);
    border: 1px solid black;

}

.parentofstats {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 134px;
}

.spanwhite {
    color: white;
    cursor: pointer;
}



.cardmain2 {
    margin: 30px 20px;
    border: 1px solid black;
}

@media screen and (max-width: 600px) {

    .secondmain {
        display: flex;
        flex-direction: column;
    }

    .cardmain {

        margin: 10px 20px;
        width: auto;

    }

}