#kt_app_content_container2 {
  margin-left: -12vw;
}

.MuiPaper-outlined {
  height: fit-content;
  padding-bottom: 20px;
}

@media screen and (max-width: 990px) {
  #kt_app_content_container2 {
    margin-left: 0vw;
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.rerenderstate {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

* {
  scroll-behavior: smooth;
}

.MuiSnackbar-root {
  bottom: 24px !important;
  /* left: 60vw !important; */
}

.submitdiv {
  width: 86vw;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 800px) {
  .MuiSnackbar-root {
    bottom: 24px !important;
    /* left: 40vw !important; */
  }
}

@media screen and (max-width: 480px) {
  .MuiSnackbar-root {
    bottom: 24px !important;
    /* left: 16vw !important; */
  }
}

@media screen and (max-width: 990px) {
  .submitdiv {
    width: 97vw;
  }


}



.time {
  background: lightgray;
  color: black;
  font-weight: 500;
  padding: 3px 3px;
}



@media only screen and (min-width: 658px) {
  .leftcontainer {
    /* border-right: 2px solid !important; */
    width: 322px
  }

  .rightcontainer {
    margin-left: 67px
  }
}